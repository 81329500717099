const State = Object.freeze({
    OFFLINE: 'OFFLINE',
    ONLINE: 'ONLINE',
    BUSY: 'BUSY',
    LIVE: 'LIVE',
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    CREATED: 'CREATED',
    UPDATED: 'UPDATED',
    DELETED: 'DELETED',
    CONTENT_UPDATED: 'CONTENT_UPDATED'
});

export { State };
